import { useAutoAnimate } from '@formkit/auto-animate/react'
import {
  HTMLAttributes,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
} from 'react'
import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form'

import ErrorMessage from '../ErrorMessage'
import * as S from './styles'

export interface InputFieldProps<TFormValues> {
  name: Path<TFormValues>
  label: string
  placeholder: string
  type?: HTMLInputTypeAttribute
  register: UseFormRegister<TFormValues>
  errors?: DeepMap<FieldValues, FieldError>
  disabled?: InputHTMLAttributes<HTMLInputElement>['disabled']
  className?: HTMLAttributes<HTMLDivElement>['className']
}

const InputField = <TFormValues extends FieldValues>({
  name,
  label,
  placeholder,
  type,
  register,
  errors,
  disabled,
  className,
}: InputFieldProps<TFormValues>) => {
  const [autoAnimate] = useAutoAnimate<HTMLDivElement>()
  return (
    <S.Container ref={autoAnimate} className={className}>
      <S.Label htmlFor={name}>{label}</S.Label>
      <S.Input
        id={name}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        error={errors?.[name]?.message}
        {...register(name)}
      />
      <ErrorMessage name={name} errors={errors} />
    </S.Container>
  )
}

export default InputField
