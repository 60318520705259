import tw, { styled } from 'twin.macro'

export interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'outlined' | 'link'
  size?: 'small' | 'medium' | 'large'
  children: React.ReactNode
  disabled?: boolean
}

const Button = styled.button<ButtonProps>(
  ({ variant, size = 'medium', disabled }) => [
    tw`px-8 py-2 rounded-full`,
    tw`bg-brand-primary-100  text-brand-secondary-100`,
    tw`hover:bg-brand-primary-120 duration-150`,
    tw`disabled:bg-brand-primary-100`,

    variant === 'primary' && [
      tw`bg-brand-secondary-80 text-white font-montserrat-medium`,
      tw`hover:bg-brand-secondary-100`,
      tw`disabled:bg-brand-secondary-80`,
    ],

    variant === 'secondary' && [
      tw`bg-functional-half-dark text-white font-montserrat-medium`,
      tw`hover:bg-functional-half-darkest`,
      tw`disabled:bg-functional-half-dark`,
    ],

    variant === 'outlined' && [
      tw`bg-transparent font-montserrat-medium border-2 border-brand-secondary-100`,
      tw`hover:bg-transparent hover:ring-1  hover:ring-brand-secondary-100`,
      tw`disabled:bg-transparent disabled:border-brand-secondary-100 disabled:text-brand-secondary-100`,
    ],

    variant === 'link' && [
      tw`text-brand-secondary-100 text-sm underline text-center cursor-pointer m-auto`,
      tw`hover:text-brand-secondary-40 duration-150`,
      tw`bg-transparent hover:bg-transparent`,
    ],

    size === 'small' && [tw`text-sm px-5 py-1.5`],

    size === 'medium' && [tw`text-base`],

    size === 'large' && [tw`text-lg`],

    disabled && tw`cursor-not-allowed opacity-40`,
  ],
)

export default Button
