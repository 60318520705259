export enum Routes {
  Login = '/login',
  Register = '/register',
  RegisterSuccess = '/register-success',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  TermsAndConditions = '/terms-and-conditions',
  MyAccount = '/',
  BankInformation = '/bank-information',
  MyCompany = '/my-company',
  MyVehicles = '/my-vehicles',
}

export enum ExternalRoutes {
  AppStore = 'https://apps.apple.com/us/app/cride-driver/id1516876472',
  LinkedIn = 'https://www.linkedin.com/company/crideapp',
  Instagram = 'https://www.instagram.com/crideapp',
  Facebook = 'https://www.facebook.com/cRIDEapp',
  Support = 'mailto:contact@getcride.com?subject=cRIDE - Support',
  ShareThoughts = 'mailto:contact@getcride.com?subject=cRIDE - Support',
  TermsAndConditions = 'https://www.getcride.com/privacy',
}
