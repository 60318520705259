export const REQUIRED_CHAR = '*'
export const MAX_FILE_SIZE = 2500000
export const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png']
export const ACCEPTED_FILE_TYPES = [...ACCEPTED_IMAGE_TYPES, 'application/pdf']
export const BLOB_REGEX = /blob:.*?/
export const DEFAULT_REGION_CODE = 'US'
export const REQUIRED = 'required-field'
export const INVALID_EMAIL = 'invalid-email'
export const INVALID_PHONE_NUMBER = 'invalid-phone-number'
export const INVALID_WEBSITE = 'invalid-website'
export const INVALID_DATE = 'invalid-date'
export const INVALID_OLD_DATE = 'invalid-old-date'
export const INVALID_FILE_TYPE = 'invalid-file-type'
export const PASSWORD_DO_NOT_MATCH = 'password-do-not-match'
export const TOO_SHORT = 'too-short'
export const MUST_BE_UPPERCASE = 'must-be-uppercase'
export const MIN_LENGTH_9 = 'min-length-9'
export const REQUIRED_TYPE = { invalid_type_error: REQUIRED }
