/**
 *
 * ErrorMessage
 *
 */

import { ErrorMessage as Error } from '@hookform/error-message'
import { useTranslation } from 'next-i18next'
import { DeepMap, FieldError, FieldValues, Path } from 'react-hook-form'

import * as S from './styles'

export interface ErrorMessageProps {
  name: Path<FieldValues>
  errors?: DeepMap<FieldValues, FieldError>
}

const ErrorMessage = ({ name, errors }: ErrorMessageProps) => {
  const { t } = useTranslation(['validation'])
  return (
    <Error
      errors={errors}
      name={name}
      render={({ message, messages }) => (
        <>
          <S.Message>{t(message)}</S.Message>
          {messages &&
            Object.entries(messages).map(([type, message]) => (
              <S.Message key={type}>{t(message as string)}</S.Message>
            ))}
        </>
      )}
    />
  )
}

export default ErrorMessage
