import Link from 'next/link'

export interface ExternalLinkProps {
  href: string
  children: React.ReactNode
  label?: string
  className?: string
}

const ExternalLink = ({
  href,
  children,
  label,
  className,
}: ExternalLinkProps) => {
  return (
    <Link href={href} passHref>
      <a
        target="_blank"
        rel="noopener noreferrer"
        aria-label={label}
        className={className}
      >
        {children}
      </a>
    </Link>
  )
}

export default ExternalLink
