import { ApiError } from 'api/types'
import { FieldValues, Path } from 'react-hook-form'
import { UseFormSetError } from 'react-hook-form'

export type ApiErrorData = {
  data?: ApiError
  status: number
}

const handleFormError = <T extends FieldValues>(
  error: ApiErrorData,
  setError: UseFormSetError<T>,
) => {
  if (error?.data?.field) {
    const field = error.data.field
    for (const name in field) {
      setError(name as Path<T>, {
        type: 'manual',
        message: field[name] as any,
      })
    }
  }
}

export default handleFormError
