import tw, { styled } from 'twin.macro'

interface InputProps {
  error?: boolean
}

export const Container = styled.div()

export const Label = styled.label([
  tw`text-brand-secondary-100 text-xs font-semibold`,
])

export const Input = styled.input<InputProps>(({ error, disabled }) => [
  tw`text-sm w-full rounded-lg border border-brand-secondary-80`,
  tw`focus:outline-none hover:ring-1 hover:ring-brand-secondary-100 focus:ring-1 focus:ring-brand-secondary-100`,
  tw`py-2 pl-3`,
  tw`bg-brand-secondary-10`,
  error &&
    tw`border-feedback-danger-medium ring-1 ring-feedback-danger-medium hover:ring-feedback-danger-medium focus:ring-feedback-danger-medium`,
  disabled && tw`border-gray-400 hover:ring-0 text-gray-400`,
])
